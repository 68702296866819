import React, { useState, useEffect } from 'react';
import './App.css';

const status = 'open' //'open' | 'sold-out' | 'closed-for-season'
const year = 2024

function Welcome(props) {
  return (
    <div>
      <h1>Welcome</h1>
      Riverside Acres is a family run tree farm. We have over three acres of Christmas trees of varying size and variety.
      Bring the family out to browse our trees and cut your own tree! Fresh cut trees smell better and last longer than
      those shipped in from outside our area.
    </div>
  )
}

function Prices(props) {
  return (
    <div>
      <h1>Prices</h1>
      We sell our trees for $12/ft up to a maximum of $75. We charge the same price regardless of variety. We hope you enjoy
      walking through the trees and picking out your favorite. For payment we accept cash or check only.
    </div>
  )
}

function Alert(props) {
  return (
    <div className="app-alert">
      {props.children}
    </div>
  )
}

function hoursMsg(props) {
  switch (status) {
    case 'open':
      return (
        <>
          We will be open Friday Nov 29 and then Saturdays and Sundays until Christmas from 9am - 4pm (while supplies last).<br/>
          <br/><b>Our supply of mature trees is limited this year so come early for best selection</b>
        </>
      )
    case 'closed-for-season':
      return (
        <Alert>
          Attention: We are sorry to announce that we will not be selling Christmas trees in {year}. 
          We are taking this action to improve our stock of mature trees and ensure you enjoy
          future visits to our farm. 
          <br/><br/>
          We plant many new tree's each spring and expect to have an ample supply of trees 
          for sale in {year+1}. Thank you for considering us we look forward to serving you again!
        </Alert> 
      )
    case 'sold-out':
      return (
        <Alert>
        Attention: We are sorry to announce that we are "Sold Out" of trees for the {year} Season. 
        High demand and an area tree shortage quickly exceeded our supply 
        of mature trees. We have many young tree's that will mature by next year though, so we hope to 
        see you again in {year+1}. Thank you for your patronage!
      </Alert>
      )
    default:
      return <></>
  }
}

function Hours(props) {
  return (
    <div>
      <h1>Hours</h1>
      {hoursMsg(props)}
    </div>
  )
}

function Location(props) {
  return (
    <div>
      <h1>Location</h1>
      The tree farm is located at:<br />
      26579 608th ST<br />
      Mantorville, MN 55955<br />
      <br />
      Find us
      <button><a target="_none" href="https://www.google.com/maps/place/26579+608th+St,+Mantorville,+MN+55955/@44.0653735,-92.6258401,12z/data=!4m2!3m1!1s0x87f76a06dfa61121:0xdac2108a189e791d">here</a></button>
      on the map or by the directions below:<br />
      <br />
      Directions from Rochester
      <ul>
        <li>Take US Hwy 14 west to Kasson (14 miles)</li>
        <li>Take MN Hwy 57 north to Mantorville(3.1 miles)</li>
        <li>Take 5th St east to Riverside Acres (2.6 miles). Note, 5th St becomes 608th St about a mile out of town</li>
        <li>Riverside acres is the last driveway on the righthand side of the road. Look for the tree farm sign</li>
      </ul>
      Directions from Dodge Center
      <ul>
        <li>Take US Hwy 14 east to Kasson (6.6 miles)</li>
        <li>Take MN Hwy 57 north to Mantorville(3.1 miles)</li>
        <li>Take 5th St east to Riverside Acres (2.6 miles). Note, 5th St becomes 608th St about a mile out of town</li>
        <li>Riverside acres is the last driveway on the righthand side of the road. Look for the tree farm sign</li>
      </ul>
    </div>
  )
}

function ContactUs(props) {
  return (
    <div>
      <h1>Contact us</h1>
      You can reach us by:<br />
        {/* <!--phone: <br>--> */}
      email: <a href="mailto:info@riversideacrestreefarm.com?Subject=Tree Farm Inquiry" target="_top">info@riversideacrestreefarm.com</a> <br />
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Riverside Acres Tree Farm</h1>
      </header>
      <nav></nav>
      <body>
        <section>
          <Welcome />
          <Prices />
          <Hours />
          <Location />
          <ContactUs/>
        </section>
      </body>
      <footer>
        Copyright © RiversideAcresTreeFarm.com
      </footer>
    </div>
  );
}

export default App;
